<template>
  <div class="dashboard-search__item" v-if="item">
  <div class="dashboard-search__item-left col-md-4 col-sm-12">
    <div class="dashboard-search__item-wish" @click="addToWishList(item)">
      <inline-svg :src="require(`@/assets/images/svg/wish-ic-empty.svg`)"></inline-svg>
    </div>
    <div class="dashboard-search__item-title">
      {{item.product_name}}
    </div>
    <div class="dashboard-search__item-span" v-if="item.ndc">
      NDC {{item.ndc}}
    </div>
    <div class="dashboard-search___item-span" v-if="item.address">
      {{item.address}}
    </div>
  </div>
  <div class="dashboard-search__item-center col-md-5 col-sm-12">
    <div class="dashboard-search__item-info">
      <div class="dashboard-search__item-name">strength</div>
      <div class="dashboard-search__item-value">{{item.strength}}</div>
    </div>
    <div class="dashboard-search__item-info">
      <div class="dashboard-search__item-name">Dosage form</div>
      <div class="dashboard-search__item-value">{{item.dosage_form}}</div>
    </div>
    <div class="dashboard-search__item-info">
      <div class="dashboard-search__item-name">Lot</div>
      <div class="dashboard-search__item-value">{{item.lot}}</div>
    </div>
    <div class="dashboard-search__item-info">
      <div class="dashboard-search__item-name">MFR</div>
      <div class="dashboard-search__item-value">{{item.manufacturer_name}}</div>
    </div>
    <div class="dashboard-search__item-info">
      <div class="dashboard-search__item-name">Pack quantity</div>
      <div class="dashboard-search__item-value"><span v-if="item.pack_quantity">FULL</span><span v-else>Partial</span></div>
    </div>
    <div class="dashboard-search__item-info">
      <div class="dashboard-search__item-name">Original pack size</div>
      <div class="dashboard-search__item-value">{{item.original_pack_size}}</div>
    </div>
    <div class="dashboard-search__item-info">
      <div class="dashboard-search__item-name">Exp date</div>
      <div class="dashboard-search__item-value">{{item.expiration_date}}</div>
    </div>
    <div class="dashboard-search__item-info">
      <div class="dashboard-search__item-name">package condition</div>
      <div class="dashboard-search__item-value">{{item.package_condition_id.title}}</div>
    </div>
  </div>
  <div class="dashboard-search__item-right col-md-3 col-sm-12">
    <div class="dashboard-search__item-top">
      <div class="dashboard-search__item-price">
        ${{item.price}}
      </div>
      <div class="dashboard-search__item-count">
        <input type="text" v-model="item.count" v-mask="'###'">
        <div class="dashboard-search__item-match">
          <div class="dashboard-search__item-plus" @click="plusProductCount(item)">
            <inline-svg :src="require(`@/assets/images/svg/arrow-input-top.svg`)"></inline-svg>
          </div>
          <div class="dashboard-search__item-minus" @click="minusProductCount(item)">
            <inline-svg :src="require(`@/assets/images/svg/arrow-input-down.svg`)"></inline-svg>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-search__item-bottom">
      <button class="btn add" @click="addProductToCart">Add to cart</button>
    </div>
  </div>
  </div>
</template>
<script>
import {mapActions, mapMutations} from "vuex";

export default {
  props:['item'],
  methods:{
    plusProductCount(item) {
      if(item.count + 1 <= item.quantity_in_stock){
        item.count++;
        this.$forceUpdate();
      }
    },
    minusProductCount(item) {
      if(item.count - 1 >= 1){
        item.count--;
        this.$forceUpdate();
      }
    },
    addProductToCart(){
      const payload = {
        id: this.item.id,
        quantity: this.item.count,
      }
      this.addToCart(payload).then(res=>{
        if(res.status) {
          this.toggleAlertPopup({
            status: true,
            content: {title: 'Success!', text: 'Product success added in cart.', type: "success"}
          });
          this.getCartProducts();
        }
        else{
          this.toggleAlertPopup({
            status: true,
            content: {title: 'Error!', text: JSON.stringify(res.data), type: "success"}
          })
        }

      })
    },
    addToWishList(item){
      this.changeWishItem(item);
      this.$router.push('/add-to-wishList');
    },
    ...mapActions({
      addToCart:'product/addToCart',
      getCartProducts:'product/getCartProducts',
    }),
    ...mapMutations({
      toggleAlertPopup:'general/toggleAlertPopup',
      changeWishItem:'general/changeWishItem',
    })
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
  .dashboard-search{
    position: relative;
    padding: 45px 0;
  &:after{
     content: '';
     position: absolute;
     left: 0;
     top: 0;
     height: 250px;
     width: 100vw;
     background-color: $grey;
     z-index: -1;
   }
  &__title{
     margin-bottom: 35px;
     font-size: 24px;
     font-family: 'Mont', Arial;
     font-weight: 900;
     text-transform: uppercase;
     color: white;
  span{
    position: relative;
  &:after{
     content: '';
     position: absolute;
     height: 20px;
     width: 20px;
     background-color: $yellow;
     border-radius: 50%;
     top: -20px;
     right: -50px;
   }
  }
  }
  &__list{
     display: flex;
     flex-direction: column;
   }
  &__item{
     box-shadow: 0px 0px 11px rgba(59, 64, 240, 0.1);
     border-radius: 27px;
     background-color: $white;
     padding: 20px 30px;
     margin-bottom: 30px;
     display: flex;
     justify-content: space-between;
     align-items: center;
  @media screen and (max-width: 1220px) {
    flex-direction: column;
  }
  }
  &__item-center{
     display: flex;
     flex-direction: column;
   }
  &__item-info{
     display: flex;
     align-items: flex-end;
   }
  &__item-wish{
     cursor: pointer;
     margin-bottom: 15px;
  &:hover{
     opacity: 0.5;
   }
  }
  &__item-title{
     font-family: 'Inter', Arial;
     font-weight: 900;
     color: $grey;
     font-size: 22px;
     line-height: 1.4;
     margin-bottom: 30px;
   }
  &__item-span{
     width: 100%;
     padding: 13px 20px;
     background-color: $white-grey;
     border-radius: 13px;
     font-size: 16px;
     font-family: 'Inter', Arial;
     font-weight: 500;
   }
  &__item-name{
     width: 40%;
     font-family: 'Inter', Arial;
     font-weight: 400;
     font-size: 12px;
     text-transform: uppercase;
     color: $grey;
     opacity: 0.5;
     margin-bottom: 5px;
   }
  &__item-value{
     width: 60%;
     font-family: 'Inter', Arial;
     font-weight: 400;
     font-size: 16px;
     margin-bottom: 5px;
   }
  &__item-top{
     margin-bottom: 40px;
     display: flex;
     justify-content: space-between;
     align-items: center;
   }
  &__item-count{
     height: 48px;
     width: 100px;
     background-color: $white-grey;
     border: 1px solid rgba(223, 223, 223, 0.5);
     box-sizing: border-box;
     border-radius: 13px;
     position: relative;
  input{
    width: 100%;
    height: 100%;
    background-color: transparent;
    font-family: 'Inter', Arial;
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 21px;
    opacity: 0.5;
    padding: 0 30px;
  }
  }
  &__item-price{
     font-size: 26px;
     font-family: 'Inter', Arial;
     font-weight: 900;
   }
  &__item-left{
     align-self: flex-start;
   }
  &__item-match{
     position: absolute;
     right: 10px;
     top: 7px;
     display: flex;
     flex-direction: column;
     cursor: pointer;
   }
  &__item-plus,
  &__item-minus{
     height: 12px;
     opacity: 0.6;
  &:hover{
     opacity: 1;
   }
  }
  &__empty{
      p{
        text-align: center;
        padding: 10px;
        margin: 50px 5px;
        border-radius: 15px;
        background-color: $white;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
      }
    }
  }
</style>
